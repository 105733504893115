import React, { useEffect, useState } from "react";
import {
  ChakraProvider,
  Flex,
  Tag,
  Heading,
  Divider,
  Stack
} from "@chakra-ui/react";
import Editor from "@monaco-editor/react";
import * as uuid from "uuid";
import { Global } from "@emotion/react";

const defaultFn = {
  name: "Instructions",
  id: "dflt",
  language: "markdown",
  value: `### Instructions\n- The functions on the left have have been gathered from the page you launched this tool in. \n- Use the helper functions presented at the bottom to assist in un-minifying and deobfuscating all the global functions. \n- Classes and various syntax can be created and edited in real time. Your work will be saved in '`
};

const a = global;
const b = Object.entries(a).reduce((pr, th) => {
  const id = uuid.v4();
  return {
    ...pr,
    [th[0]]: {
      name: th[0] || uuid.v4(),
      id: id,
      language: "javascript",
      value: a[th[0]],
      get valueFn() {
        return eval(this.value);
      }
    }
  };
}, {});

const App = () => {
  const [currentGlobal, setCurrentGlobal] = useState("dflt");
  const showVars = window.innerWidth > "600px";
  const [data, setData] = useState(b);

  useEffect(() => {
    if (window.wonderfulWorld) {
      return setData(window.wonderfulWorld);
    }
  }, []);

  const allGlobals = {
    ...data,
    dflt: { ...defaultFn },
    toArray: {
      name: "ToArrayfn",
      id: "toArray",
      language: "javascript",
      value: "() => Object.values(allGlobals)",
      get valueFn() {
        return eval(this.value);
      }
    }
  };

  return (
    <ChakraProvider resetCSS>
      <Flex
        backgroundColor="white"
        h="100vh"
        flexDirection="row"
        bgGradient="linear(to top left, gray.900,gray.700)"
        p={2}
      >
        <Flex w="250px" flexDirection="column" p={2}>
          <Heading size="md" color="whiteAlpha.700" letterSpacing="normal">
            window fns
          </Heading>
          <Divider borderColor="whiteAlpha.500" mb={2} color="whiteAlpha.700" />
          <Flex
            p={0}
            h="full"
            flexDirection="column"
            overflowY="auto"
            overflowX="hidden"
          >
            {allGlobals.toArray.valueFn().map((i) => (
              <Tag
                cursor="pointer"
                key={i.id}
                size="sm"
                mt={1}
                onClick={() => setCurrentGlobal(i.name)}
                colorScheme={currentGlobal === i.name ? "teal" : "gray"}
                children={i.name}
              />
            ))}
          </Flex>
        </Flex>
        <Divider borderColor="whiteAlpha.500" orientation="vertical" />
        <Flex flex={1} flexDirection="column" px={2}>
          <Stack spacing={2} h={0} />
          <Flex
            w={`calc(100vw - 250px)`}
            h="full"
            backgroundColor="rgb(30,30,30)"
            borderRadius="md"
            p={2}
          >
            <Editor
              theme="vs-dark"
              path={allGlobals[currentGlobal]?.name}
              defaultLanguage={allGlobals[currentGlobal]?.language}
              defaultValue={(() => {
                if (typeof allGlobals[currentGlobal] === "string") {
                  try {
                    return allGlobals[currentGlobal]?.value;
                  } catch {
                    //pass
                  }
                }

                try {
                  return JSON.stringify(
                    allGlobals[currentGlobal]?.value,
                    null,
                    4
                  );
                } catch (e) {
                  return JSON.stringify("error: " + e);
                }
              })()}
            />
          </Flex>
        </Flex>
      </Flex>
    </ChakraProvider>
  );
};

export default App;
